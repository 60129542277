body {
  margin: 0;
  font-family: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.controller-bar::-webkit-scrollbar {
  display: none;
}

.controller-bar {
  overflow: scroll;
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}

*:focus {
  outline: none!important;
}
summary::-webkit-details-marker {
  display:none;
 }

 summary{
  background-color:#C2D1D9;
  padding: 1rem;
}

summary:hover{
  background-color:
  #81C88B;
}

/* ===== Scrollbar CSS ===== */
* {
  scrollbar-width: thin;
  scrollbar-color: #54a067 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  display: none
}

*::-webkit-scrollbar-thumb {
  background-color: #54a067;
  border-radius: 18px;
  border: 3px solid #54a067;
}

/* ===== Backdrop filter css ==== */
.backdrop-blur {
  background-color: rgba(180, 180, 180, .8);
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur {
    background-color: transparent;
    -webkit-backdrop-filter: blur(7px) contrast(0.4);
    backdrop-filter: blur(7px) contrast(0.4);
  }
}