


.pricingFeatures{
  border-left: 1px solid #828282 ;
  padding: 1rem;
  
}
.pricingFeatures:first-child{
  border: none;
}


/* @media only screen and (max-width: 600px) {
  html{
    background-color: blue;
    border: 5px solid black;
  }
  body{
    border: 5px solid black;
  }
  .pricingFeatures
  {
    border: none;
     align-self:center;

  }
} */

a:visited{
  color: inherit;
}


.link:hover{
  opacity: 1!important;
  color: black!important;
  
}

.link:focus{
  border: none;
}

input[type=radio]:checked + label>img {
  border: 1px solid #BDBDBD;
  box-shadow: 0 0 1px 1px #000;
}

.input-hidden {
  position: absolute;
  left: -9999px;
}

a:link {
  color: green;
  background-color: transparent;
  text-decoration: none;
}

.full-screen{
  height:calc(100vh - 5.5em); /*TODO App bar is 4em but we are subtracting 5.5*/
  width:100vw;
  position: 'absolute' 
}

.full-screen-home{
  height:calc(100vh - 4em); 
  width:100vw;
  
}

/* a:visited {
  color: pink;
  background-color: transparent;
  text-decoration: none;
}
 */

/* a:active {
  color: rgba(0,0,0,.5);
  
}
a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: rgba(0,0,0,.5);
}
a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
} */